import React from 'react'

export default function Home() {
  const _localUser = localStorage.getItem('name') !== null ? (localStorage.getItem('name')) : '';
  return (
    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-5 bg-[#F1F5F9] ">
      {_localUser?<div className=' w-full text-xl text-gray-600  font-bold text-center'>
Welcome To PT2000
      </div>:<div></div>}
      
      
      
      </div>
  )
}
