import React from 'react';
import Header from '../Header/Header';


import Router from '../../router/Router';
import Navbar from '../Navbar';

export default function Layout() {
  return (
    <>
      <div className="grid">
        <Navbar />
        <div className=' w-full'>
          <Router />
        </div>
        
      </div>
      
    </>
  )
}
